import { gql } from '@apollo/client';

export const UPDATE_ADMIN = gql`
    mutation ($id: String!, $input: UpdateAdminDTO!) {
        updateAdminById(id: $id, input: $input) {
            message
            admin {
                _id
                firstname
                lastname
                email
                status
                role
                phone
            }
        }
    }
`;

export const DELETE_ADMIN = gql`
    mutation ($id: String!) {
        removeAdmin(id: $id) {
            message
            admin {
                _id
            }
        }
    }
`;
